import * as React from "react";
import {
    Datagrid,
    TextField,
    ReferenceField,
    usePermissions
} from "react-admin";
import ToolLinkField from "./ToolLinkField";
import StateChipField from "./StateChipField";
import DateFieldFormatted from "../components/DateFieldFormatted";
import { getRolesWorkshopOnly } from "../appRolesComponents";

const ToolListDesktop = () => {
    const { permissions } = usePermissions();
    const isWorkshopOnly = getRolesWorkshopOnly(permissions);

    return (
        <Datagrid
            optimized
            rowClick="edit"
        >
            <ToolLinkField
                sortable={false}
            />
            <ReferenceField
                label="resources.tools.fields.tool"
                source="itemId"
                reference="items"
                link={false}
                sortable={false}
            >
                <TextField 
                    source="name"                         
                />
            </ReferenceField>
            <DateFieldFormatted
                label="Fecha de Retiro"
                source="start"
                sortable={false}
            />
            <DateFieldFormatted
                label="Fecha Devolución"
                source="end"
                sortable={false}
            />
            <ReferenceField
                label="resources.tools.fields.employee"
                source="employeeId"
                reference="employees"
                link={false}
                sortable={false}
            >
                <TextField source="fullName" />
            </ReferenceField>

            <StateChipField
                label="Estado"
                source="end"
                sortable={false}
            />
            {isWorkshopOnly &&
                <TextField
                    label="Usuario que creó/modificó por última vez"
                    source="userName"
                    sortable={false}
                />
            }
            {isWorkshopOnly &&
                <DateFieldFormatted
                    label="Cuando se creó/modificó por última vez"
                    source="lastUpdate"
                    showTime={true}
                    sortable={false}
                />
            }

        </Datagrid>
    );
};

export default ToolListDesktop;
