import React, { useEffect, useState } from 'react';
import {
    DateTimeInput,
    FormDataConsumer,
    NumberInput,
    TextInput,
} from 'react-admin';
import { Box, Card, CardContent, CardHeader, FormControl, InputAdornment, TextField } from '@material-ui/core';
import { requiredDaysInWorkshopHour, requiredValidateValue } from '../validators/validators';
import SectionTitle from '../components/SectionTitle';
import DaysInWorkshopChip from './DaysInWorkshopChip';
import ListItemsTotalPrice from './ListItemsTotalPrice';
import { useForm } from 'react-final-form';
import moment from "moment";
import NumberFormatCustom from '../components/NumberFormatCustom';
import { calculateTotal } from '../utils/functions';
import { Info as InfoIcon } from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';


const InfoMessageWithBreaks = () => (
    <Box display="flex" flexDirection="column" bgcolor="#e8f4fd" p={2} borderRadius={4} mb={2}>
        <Box display="flex" alignItems="center" mb={1}>
            <InfoIcon style={{ color: '#1976d2', marginRight: '8px' }} />
            <Typography variant="body2" style={{ color: '#1976d2' }}>
                Información de cantidades:
            </Typography>
        </Box>
        <Typography variant="body2" style={{ color: '#1976d2', marginBottom: '4px' }}>
            <b>'Total Cantidad (Usado)'</b>: Cantidad total usado en la OT (Es decir es la cantidad usado, que restaron stock por NO ser del tipo consumible + la cantidad de los consumibles que se asigno a las OT, que no resto stock).
        </Typography>
        <Typography variant="body2" style={{ color: '#1976d2', marginBottom: '4px' }}>
            <b>'Total Monto (Usado)'</b>: Monto total usado en la OT (Es decir es el monto total usado, que restaron stock por NO ser del tipo consumible + el monto total de los consumibles que se asigno a las OT, que no resto stock).
        </Typography>
        <br />
        <Typography variant="body2" style={{ color: '#1976d2', marginBottom: '4px' }}>
            <b>'Total Cantidad'</b>: Cantidad total usado en la OT que restaron Stock (Es decir es la cantidad usado + la cantidad de los consumibles que el usuario confirmó o finalizó).
        </Typography>
        <Typography variant="body2" style={{ color: '#1976d2', marginBottom: '4px' }}>
            <b>'Total Monto'</b>: Monto total usado en la OT que restaron Stock (Es decir es el monto total usado + el monto total de los consumibles que el usuario confirmó o finalizó).
        </Typography>
        <br />
        <Typography variant="body2" style={{ color: '#1976d2', marginBottom: '4px' }}>
            <b>'Cantidad (Usado)'</b>: Cantidad de cada material y/o repuesto usado en la OT.
        </Typography>
        <Typography variant="body2" style={{ color: '#1976d2', marginBottom: '4px' }}>
            <b>'Cantidad (Confirmado)'</b>: Confirmado o finalizado en la OT (Es decir en el flujo de consumibles es la cantidad que el usuario confirmó para restar Stock, y tener en cuenta que solo son para los artículos que tienen activado el campo 'Consumible' en el Pañol de artículos).
        </Typography>
        <br />
        <Typography variant="body2" style={{ color: '#1976d2', marginBottom: '4px' }}>
            <b>*Nota</b>: Tener en cuenta que para los artículos que son del tipo 'consumible', se le calcula el Subtotal en base a la cantidad confirmada por el usuario.
        </Typography>
    </Box>
);

const OrderFormBuget = (props) => {
    const form = useForm();

    const calculateDaysInWorkshop = () => {
        let m1 = moment(props.record.start);
        let m2 = moment(props.record.end);
        let m3 = m2.diff(m1, 'minutes');
        //let m4 = m2.diff(m1, 'h'); Calcula la cantidad de horas
        let numdays = Math.floor(m3 / 1440);
        let numhours = Math.floor((m3 % 1440) / 60);
        //let numminutes = Math.floor((m3 % 1440) % 60);
        let result = numdays + (numhours / 100);
        if (result < 1) { result = 1; }

        return Math.ceil(result);
    }
    form.change("daysInWorkshop", calculateDaysInWorkshop());

    const total = calculateTotal(props.record.items);
    const [state, setState] = useState(total);

    useEffect(() => {
        //TODO-- ENGENDRO QUE TUVE Q HACER XQ NO ME FUNCINA BIEN defualtValue y el formato de precios
        //TODO-- TUVE Q PONER UN TextInput oculto y guardar cada vez q cmabia un valor del TextField id="ot-total"
        if (state !== undefined && props.record.workOrderStatus !== 'PENDIENTE' && (total === props.record.total || props.record.total === null)) {
            form.change("total", total);
        }
        if (state !== undefined && total !== props.record.total) {
            setState(props.record.total);
            form.change("ot-total", state);
        }
    }, [props.record]); // eslint-disable-line react-hooks/exhaustive-deps

    const changePrice = (e) => {
        const totalValue = (e.target.value) ? e.target.value : 0;
        setState(totalValue);
        form.change("total", totalValue);
    }

    const isReadOnly = (props.record.deliveryDate !== null);

    return (
        <FormDataConsumer>
            {({ formData }) => (formData.workOrderStatus === 'FINALIZADA' || formData.workOrderStatus === 'FINALIZADA_ENTREGAR') ?
                <CardContent>
                    <Box display={{ md: 'block', lg: 'flex' }}>
                        <Box flex={1} mr={{ md: 0, lg: '1em' }}>
                            <SectionTitle label="Duración en el Taller" />
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <NumberInput
                                        label="Días en el Taller"
                                        source="daysInWorkshop"
                                        max="365"
                                        min="1"
                                        disabled={isReadOnly}
                                        validate={requiredDaysInWorkshopHour}
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="start">
                                                    Días
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Box>
                                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                    <DateTimeInput
                                        label="Fecha Inicio"
                                        source="start"
                                        disabled={true}
                                        fullWidth
                                    />
                                </Box>
                                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                    <DateTimeInput
                                        label="Fecha Fin"
                                        source="end"
                                        disabled={true}
                                        fullWidth
                                    />
                                </Box>
                            </Box>

                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>

                                </Box>
                                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                    <DaysInWorkshopChip
                                        startDate={formData.start}
                                        endDate={formData.end}
                                    />
                                </Box>
                                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>

                                </Box>
                            </Box>

                            <SectionTitle label="Total de Materiales y/o Repuestos" />
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <div style={{ display: 'none' }}>
                                        <TextInput
                                            label="Total"
                                            source="total"
                                            fullWidth
                                        />
                                    </div>
                                    <FormControl size="small" fullWidth>
                                        <TextField
                                            label="Total"
                                            id="ot-total"
                                            name="ot-total"
                                            value={state}
                                            disabled={isReadOnly}
                                            size="small"
                                            margin="dense"
                                            variant="filled"
                                            InputProps={{
                                                inputComponent: NumberFormatCustom,
                                            }}
                                            validate={requiredValidateValue}
                                            onChange={changePrice}
                                        />
                                    </FormControl>
                                    <InfoMessageWithBreaks />
                                </Box>
                                <Box flex={2} ml={{ xs: 0, sm: '0.5em' }}>
                                    <ListItemsTotalPrice  {...props} />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </CardContent>
                :
                <div>
                    <Card key={0}>
                        <CardHeader
                            title={
                                <div>
                                    No se puede calcular el total de la OT porque aún esta sin finalizar!
                                </div>
                            }
                        />
                    </Card>
                </div>
            }
        </FormDataConsumer>
    );
};

export default OrderFormBuget;
