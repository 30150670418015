import React, { useState } from "react";
import ReactExport from "react-export-excel";
import { Button, LinearProgress } from 'react-admin';
import DownloadIcon from '@material-ui/icons/GetApp';
import { dateParseWithoutTime } from '../../utils/functions';

const ItemsByBusLinesExport = ({ dataExport }) => {
    const [exportLoading, setExportLoading] = useState(false);

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    const handleExportClick = () => {
        setExportLoading(true);
        setTimeout(() => {
            setExportLoading(false);
        }, 1000);
    };

    const filename = `gastos-${dateParseWithoutTime(new Date())}`;
    
    const transformedData = dataExport.flatMap((record) => {
        const mainRecord = {
            busLineId: record.busLineId,
            busLineName: record.busLineName,
            customerId : record.customerId,
            customerName: record.customerName,
            year: record.year,
            month: record.month,
            totalQuantity: record.totalQuantity,
            total: record.total,
            totalToInvoiced: record.totalToInvoiced,
            articleName: "",
            articleCategory: "",
            articleQuantity: "",
            articleTotalPrice: "",
        };

        const articleRows = record.items.map((item) => ({
            busLineId: "",
            busLineName: "",
            customerId: "",
            customerName: "",
            year: "",
            month: "",
            totalQuantity: "",
            total: "",
            totalToInvoiced: "",
            articleName: item.name || "Borrado en el sistema",
            articleCategory: item.category || "Borrado en el sistema",
            articleQuantity: item.totalItemQuantity,
            articleTotalPrice: item.totalItemPrice,
        }));

        return [mainRecord, ...articleRows];
    });

    return (
        <ExcelFile
            filename={filename}
            element={
                <Button
                    label="Exportar a Excel"
                    size="small"
                    variant="contained"
                    onClick={handleExportClick}
                    disabled={exportLoading}
                >
                    {exportLoading ? <LinearProgress /> : <DownloadIcon />}
                </Button>
            }>
            <ExcelSheet data={transformedData} name="Gastos">
                <ExcelColumn label="Año" value="year" />
                <ExcelColumn label="Mes" value="month" />
                <ExcelColumn label="Nombre de Cliente" value="customerName" />
                <ExcelColumn label="Nombre de Linea" value="busLineName" />                
                <ExcelColumn label="Total Cantidad" value="totalQuantity" />
                <ExcelColumn label="Total Monto" value="total" />
                <ExcelColumn label="Total Monto a Facturar" value="totalToInvoiced" />
                <ExcelColumn label="Nombre del Artículo" value="articleName" />
                <ExcelColumn label="Categoría del Artículo" value="articleCategory" />
                <ExcelColumn label="Cantidad del Artículo" value="articleQuantity" />
                <ExcelColumn label="Monto Total del Artículo" value="articleTotalPrice" />
            </ExcelSheet>
        </ExcelFile>
    );
};

export default ItemsByBusLinesExport;
