import * as React from 'react';
import {
    Edit,
    SimpleForm,
    ReferenceManyField,
    Pagination,
    Datagrid,
} from 'react-admin';
import ConsumableForm from './ConsumableForm';
import ConsumableDesktopLines from './ConsumableDesktopLines';
import CustomOTField from './CustomOTField';
import DateFieldFormatted from '../components/DateFieldFormatted';
import CustomDateTimeConfirmedField from './CustomDateTimeConfirmedField';
import WorkOrderStatusColorChip from '../components/WorkOrderStatusColorChip';

const ConsumableEdit = (props) => {
    const Title = ({ record }) => {
        return <span>{record ? `${record.name}` : ''}</span>;
    };

    return (
        <Edit
            {...props}
            title={<Title />}
        >
            <SimpleForm toolbar={<div></div>}>
                <ConsumableForm />

                <ReferenceManyField
                    label={false}
                    perPage={10}
                    sort={{ field: 'start', order: 'DESC' }}
                    pagination={<Pagination />}
                    reference="consumables"
                    target="itemId"
                    fullWidth
                >
                    <Datagrid
                        optimized
                        expand={<ConsumableDesktopLines />}
                    >
                        <CustomOTField
                            label="Orden de Trabajo"
                            sortable={false}
                        />
                        <DateFieldFormatted
                            label="Fecha Inicio"
                            source="start"
                            sortable={false}
                        />
                        <DateFieldFormatted
                            label="Fecha Fin"
                            source="end"
                            sortable={false}
                        />
                        <DateFieldFormatted
                            label="Fecha Entrega"
                            source="deliveryDate"
                            sortable={false}
                        />
                        <WorkOrderStatusColorChip 
                            label="Estado"
                            source="workOrderStatus"
                            sortable={false}
                        />

                        <CustomDateTimeConfirmedField
                            label="Fecha Confirmación"
                            source="dateTimeConfirmed"
                            sortable={false}
                        />
                    </Datagrid>

                </ReferenceManyField>
            </SimpleForm>
        </Edit>
    );
};

export default ConsumableEdit;
