import React from 'react';
import {
    useGetList,
    LinearProgress
} from 'react-admin';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 300,
        maxWidth: 300,
    },
}));

const EmployeesSelectInput = ({ product, employees, addEmployeeToItem }) => {
    const classes = useStyles();
    const { data, loaded, error } = useGetList(
        'employees',
        { page: 1, perPage: 100 },
        { field: 'fullName', order: 'ASC' },
    );

    if (!loaded) return <LinearProgress />;
    if (error) { return <p>ERROR</p>; }
    if (!data || Object.keys(data).length === 0) return null;

    // Construir opciones
    const choices = employees
        .map((employeeId) => {
            const employeeData = data[employeeId]; // Acceso directo al empleado por ID
            return employeeData
                ? { id: employeeData.id, name: employeeData.fullName }
                : null;
        })
        .filter(Boolean); // Eliminar nulls si no se encuentran empleados

    return (
        <FormControl className={classes.formControl} size="small">
            <InputLabel id="simple-select-label">&nbsp;Empleado</InputLabel>
            <Select
                labelId="simple-select-label"
                id="simple-select"
                value={product.employeeId || ''}
                onChange={(e) => addEmployeeToItem(product, e)}
                variant="filled"
            >
                {choices.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                        {item.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default EmployeesSelectInput;
