import * as React from 'react';
import { useState } from 'react';
import {
    Title,
    BooleanInput,
    SelectInput,
    ReferenceInput,
} from 'react-admin';
import { withTypes } from 'react-final-form';
import { Button, Card, Box, CardContent, CardActions, CircularProgress, makeStyles } from '@material-ui/core';
import { requiredValidate } from '../../validators/validators';
import ItemsDatagrid from './ItemsDatagrid';

const useStyles = makeStyles({
    root: {
        margin: '5px',
    },
});
const { Form } = withTypes();

const ReportItems = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState(false);
    const [values, setValues] = useState();

    const handleSubmit = (values) => {
        if (values && Object.keys(values).length > 0) {
            setLoading(true);
            setValues(values);
            setState(true);
            setLoading(false);
        } else {
            setLoading(false);
        }
    };

    const handleButtonClick = () => {
        if (state) {
            setState(false);
            setValues(undefined);
        } else {
            handleSubmit(values);
        }
    };

    return (
        <div>
            <Form
                onSubmit={handleSubmit}
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <Card className={classes.root}>
                            <Title title={'Reporte Articulos del Pañol'} />
                            <CardContent>
                                <Box display={{ md: 'block', lg: 'flex' }}>
                                    <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                        <Box display={{ xs: 'block', sm: 'flex' }}>
                                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                                <ReferenceInput
                                                    label="Categoria"
                                                    source="categoryId"
                                                    reference="categories"
                                                    sort={{ field: 'name', order: 'ASC' }}
                                                    validate={requiredValidate}
                                                    fullWidth
                                                    resettable
                                                >
                                                    <SelectInput
                                                        source="name"
                                                    />
                                                </ReferenceInput>
                                            </Box>
                                        </Box>
                                        <Box display={{ xs: 'block', sm: 'flex' }}>
                                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                                <BooleanInput
                                                    label="Solo articulos sin stock del pañol (stock = 0)"
                                                    source="quantity"
                                                />
                                            </Box>
                                            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box
                                        flex={2}
                                        ml={{ xs: 0, lg: '1em' }}
                                        mt={{ xs: '1em', lg: 0 }}
                                    >
                                        <Box display={{ xs: 'block', sm: 'flex' }}>
                                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                                <CardActions>
                                                    <Button
                                                        variant="contained"
                                                        type="submit"
                                                        color="primary"
                                                        disabled={loading}
                                                        onClick={handleButtonClick}
                                                    >
                                                        {loading && (<CircularProgress size={25} thickness={2} />)}

                                                        {state ? 'Volver a Buscar' : 'Ver Articulos'}
                                                    </Button>
                                                </CardActions>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                {state &&
                                    <ItemsDatagrid values={values} />
                                }
                            </CardContent>
                        </Card>
                    </form>
                )}
            />
        </div>

    );
};

export default ReportItems;
