import React, { useEffect, useState } from 'react';
import {
    TextInput,
    DateTimeInput,
} from 'react-admin';
import authProvider from "../authProvider";
import InspectionDetailsField from './InspectionDetailsField';
import InspectionDetailsView from './InspectionDetailsView';
import { Box, CardContent, useMediaQuery } from '@material-ui/core';
import { requiredValidate } from '../validators/validators';
import { dateParseDateTime, toUpperCase } from '../utils/functions';
import SectionTitle from '../components/SectionTitle';
import EmployeesInput from './EmployeesInput';
import { parse } from 'query-string';
import { useForm } from 'react-final-form';
import InspectionMobileDetailsField from './InspectionMobileDetailsField';
import EmployeesChipField from '../components/EmployeesChipField';

const OrderForm = (props) => {
    const user = authProvider.getIdentity();
    const form = useForm();
    const isXsmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const [state, setState] = useState()

    let inspectionId = props.record.inspectionId;
    let employeeId = [];

    if (inspectionId === undefined) {
        const {
            id: inspection_id_string,
            employeeId: employee_id_string
        } = parse(props.location.search);

        inspectionId = inspection_id_string ? parseFloat(inspection_id_string) : '';
        employeeId.push(employee_id_string);
        form.change("employeeId", employeeId);
    }

    const isReadOnly = (props.record.inspectionId !== undefined && (props.record.workOrderStatus !== 'PENDIENTE' && props.record.workOrderStatus !== 'EN_PROGRESO'));

    useEffect(() => {
        setState(isReadOnly)
    }, [isReadOnly])

    return (
        <CardContent>
            <Box display={{ md: 'block', lg: 'flex' }}>
                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                    <SectionTitle label="Inicio de la OT" />
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            {(props.id === undefined || props.record.workOrderStatus === 'PENDIENTE') ?
                                <DateTimeInput
                                    label="Fecha de Inicio"
                                    source="start"
                                    defaultValue={dateParseDateTime(Date.now())}
                                    validate={requiredValidate}
                                    parse={dateParseDateTime}
                                    fullWidth
                                />
                                :
                                <DateTimeInput
                                    label="Fecha Inicio"
                                    source="start"
                                    disabled={true}
                                    fullWidth
                                />
                            }
                        </Box>
                    </Box>
                    <SectionTitle label="Estado de la OT" />
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            {(props.id === undefined) ?
                                <TextInput
                                    label="Estado"
                                    source="workOrderStatus"
                                    disabled={true}
                                    defaultValue={"PENDIENTE"}
                                    fullWidth
                                />
                                :
                                <TextInput
                                    label="Estado"
                                    source="workOrderStatus"
                                    disabled={true}
                                    fullWidth
                                />
                            }
                        </Box>
                    </Box>
                </Box>
                <Box
                    flex={6}
                    ml={{ xs: 0, lg: '1em' }}
                    mt={{ xs: '1em', lg: 0 }}
                >
                    <SectionTitle label="Grupo de Trabajo" />
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            {isReadOnly ?
                                <EmployeesChipField employeeId={props.record.employeeId} />
                                :
                                <EmployeesInput />
                            }
                        </Box>
                    </Box>
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="Descripción"
                                source="description"
                                parse={toUpperCase}
                                disabled={state}
                                rows={3}
                                multiline
                                resettable
                                fullWidth
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Box display={{ xs: 'block', sm: 'flex' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    <SectionTitle label="Detalle de la Inspección" />
                    <InspectionDetailsView id={inspectionId} />
                </Box>
            </Box>

            <Box mt="1em" />

            <Box display={{ xs: 'block', sm: 'flex' }}>
                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                    {isXsmall ? (
                        <InspectionMobileDetailsField id={inspectionId} />
                    ) : (
                        <InspectionDetailsField id={inspectionId} />
                    )}
                </Box>
            </Box>

            {/* //TODO --PARA GUARDAR DATOS EN LA BD */}
            <div style={{ display: 'none' }}>
                <TextInput
                    source="userId"
                    defaultValue={user.id}
                    disabled={true}
                    fullWidth
                />
                <TextInput
                    source="userName"
                    defaultValue={user.username}
                    disabled={true}
                    fullWidth
                />

                <TextInput
                    source="inspectionId"
                    disabled={true}
                    defaultValue={inspectionId}
                    fullWidth
                />
            </div>
        </CardContent>
    );
};

export default OrderForm;
