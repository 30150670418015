import React from 'react';
import {
    TextInput,
    DateTimeInput,
    ReferenceInput,
    AutocompleteInput,
} from 'react-admin';
import { requiredValidate } from '../validators/validators';
import {
    toUpperCase,
    dateParseDateTime
} from '../utils/functions';
import { Box, CardContent } from '@material-ui/core';
import SectionTitle from '../components/SectionTitle';
import { useForm } from 'react-final-form';

const PurchaseForm = (props) => {
    const form = useForm();

    if (props.id && props.record.purchaseStatus === 'CREADA') {
        form.change("deliveryDate", dateParseDateTime(Date.now()));
    }

    return (
        <CardContent>
            <Box display={{ md: 'block', lg: 'flex' }}>
                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                    <SectionTitle label="Orden de Compra" />
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            {(props.id === undefined) ?
                                <DateTimeInput
                                    autoFocus
                                    label="Fecha de Inicio"
                                    source="date"
                                    validate={requiredValidate}
                                    defaultValue={dateParseDateTime(Date.now())}
                                    parse={dateParseDateTime}
                                    fullWidth
                                />
                                :
                                <DateTimeInput
                                    autoFocus
                                    label="Fecha"
                                    source="date"
                                    validate={requiredValidate}
                                    parse={dateParseDateTime}
                                    fullWidth
                                />
                            }
                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <DateTimeInput
                                label="Fecha Pactada"
                                source="agreedDate"
                                validate={requiredValidate}
                                parse={dateParseDateTime}
                                fullWidth
                            />
                        </Box>
                    </Box>

                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <ReferenceInput
                                label="Buscar Proveedor por Nombre o Fantasia"
                                source="supplierId"
                                reference="suppliers"
                            >
                                <AutocompleteInput
                                    optionText={(record) => {
                                        return record ? `${record.name} - ${record.nameFantasy}` : '';
                                    }}
                                    validate={requiredValidate}
                                    resettable
                                    fullWidth
                                />
                            </ReferenceInput>

                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="Referencia"
                                source="reference"
                                parse={toUpperCase}
                                resettable
                                fullWidth
                            />
                        </Box>
                    </Box>
                </Box>

                <Box
                    flex={1}
                    ml={{ xs: 0, lg: '1em' }}
                    mt={{ xs: '1em', lg: 0 }}
                >
                    {(props.id) && (
                        <div>
                            <SectionTitle label="Recepción y Entrega" />
                            {/* <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <DateTimeInput
                                        label="Fecha de Entrega"
                                        source="deliveryDate"
                                        defaultValue={dateParseDateTime(Date.now())}
                                        parse={dateParseDateTime}
                                        disabled={true}
                                        fullWidth
                                    />
                                </Box>
                            </Box> */}
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <TextInput
                                        label="Observaciones y detalles de la Entrega"
                                        source="details"
                                        parse={toUpperCase}
                                        rows={4}
                                        multiline
                                        resettable
                                        fullWidth
                                    />
                                </Box>
                            </Box>
                        </div>
                    )}
                </Box>
            </Box>
        </CardContent>
    );
};

export default PurchaseForm;
