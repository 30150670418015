import React from 'react';
import {
    TextInput,
    ReferenceInput,
    AutocompleteInput,
    DateTimeInput,
    FormDataConsumer,
    usePermissions,
} from 'react-admin';
import authProvider from "../authProvider";
import { Box, CardContent, useMediaQuery } from '@material-ui/core';
import { requiredValidate } from '../validators/validators';
import SectionTitle from '../components/SectionTitle';
import { dateParseDateTime } from '../utils/functions';
import VehicleDetailsField from './VehicleDetailsField';
import VehicleMobileDetailsField from './VehicleMobileDetailsField';
import InspectionFormVehicle from './InspectionFormVehicle';
import InspectionFormDetail from './InspectionFormDetail';
import { getRolesOnlyDriverExceptAdmin } from '../appRolesComponents';
//import { getRolesOnlyDriverExceptAdmin } from '../appRolesComponents';

const InspectionForm = (props) => {
    const user = authProvider.getIdentity();
    const { permissions } = usePermissions();
    const isXsmall = useMediaQuery(theme => theme.breakpoints.down('xs'));

    const isDriver = getRolesOnlyDriverExceptAdmin(permissions);

    return (
        <CardContent>
            <Box display={{ md: 'block', lg: 'flex' }}>
                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                    <SectionTitle label="Cabecera de la Inspección" />
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        {(props.record.id !== undefined) &&
                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                <TextInput
                                    label="Inspección"
                                    source="id"
                                    disabled={true}
                                    fullWidth
                                />
                            </Box>
                        }
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            {(props.record.id !== undefined) ?
                                <DateTimeInput
                                    label="resources.inspections.fields.dateInspection"
                                    source="start"
                                    validate={requiredValidate}
                                    parse={dateParseDateTime}
                                    fullWidth
                                />
                                :
                                <DateTimeInput
                                    label="resources.inspections.fields.dateInspection"
                                    source="start"
                                    defaultValue={dateParseDateTime(Date.now())}
                                    validate={requiredValidate}
                                    parse={dateParseDateTime}
                                    fullWidth
                                />
                            }
                        </Box>
                        <Box flex={3} mr={{ xs: 0, sm: '0.5em' }}>
                            <ReferenceInput
                                label="resources.inspections.fields.inspector"
                                source="employeeId"
                                reference="employees"
                                fullWidth
                                resettable
                            >
                                <AutocompleteInput
                                    optionText="fullName"
                                    validate={requiredValidate}
                                />
                            </ReferenceInput>
                        </Box>

                        <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
                        {/* TODO FORMA DE NO HACER UN FETCH 999999 */}
                        <ReferenceInput
                                label="Busque el vehículo"
                                source="vehicleId"
                                reference="vehicles"
                                fullWidth
                                resettable
                                filter={{ filterByPatent: true }}
                            >
                                <AutocompleteInput
                                    optionText="patent"
                                    validate={requiredValidate}
                                />
                            </ReferenceInput>
                        </Box>

                    </Box>

                    <FormDataConsumer>
                        {({ formData }) => (formData.vehicleId !== undefined) ? (
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    {isXsmall ? (
                                        <div>
                                            <VehicleMobileDetailsField id={formData.vehicleId} />
                                            <InspectionFormVehicle {...props} />
                                            {(!isDriver) &&
                                                <InspectionFormDetail />
                                            }
                                        </div>
                                    ) : (
                                        <div>
                                            <VehicleDetailsField id={formData.vehicleId} />
                                            <InspectionFormVehicle {...props} />
                                            {(!isDriver) &&
                                                <InspectionFormDetail />
                                            }
                                        </div>

                                    )}
                                </Box>
                            </Box>
                        ) :
                            <div>{
                                () => (localStorage.removeItem('busTemplate'))
                            }</div>
                        }
                    </FormDataConsumer>
                </Box>
            </Box>


            {/* //TODO --PARA GUARDAR DATOS EN LA BD */}
            <div style={{ display: 'none' }}>
                <TextInput
                    source="userId"
                    defaultValue={user.id}
                    disabled={true}
                    fullWidth
                />
            </div>
        </CardContent>
    );
};

export default InspectionForm;
