import React from 'react';
import {
    TextInput,
    SelectInput,
    ReferenceInput,
    AutocompleteInput,
    DateInput,
    FormDataConsumer,
} from 'react-admin';
import { Box, CardContent } from '@material-ui/core';
import { requiredValidate, requiredValidateLengthInvoice, requiredValidatePointOfSale } from '../validators/validators';
import { toUpperCase, dateParse, dateParseWithoutTime } from '../utils/functions';
import purchaseInvoiceData from '../components/data/purchaseInvoiceData';
import SectionTitle from '../components/SectionTitle';
import PurchaseInvoiceDates from './PurchaseInvoiceDates';
import SupplierCard from './SupplierCard';
import conceptsData from '../components/data/conceptsData';

const PurchaseInvoiceForm = (props) => {
    const handleSuppliers = () => {
        localStorage.removeItem('purchasesInvoiceCart');
    }

    const isReadOnly = (props.record.id !== undefined) ? true : false;

    return (
        <CardContent>
            <Box display={{ md: 'block', lg: 'flex' }}>
                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                    <SectionTitle label="Cabecera del Comprobante" />
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={3} mr={{ xs: 0, sm: '0.5em' }}>
                            {(props.record.id === undefined) ?
                                <DateInput
                                    label="Fecha"
                                    source="date"
                                    defaultValue={dateParseWithoutTime(Date.now())}
                                    validate={requiredValidate}
                                    parse={dateParse}
                                    fullWidth
                                />
                                :
                                <DateInput
                                    label="Fecha"
                                    source="date"
                                    validate={requiredValidate}
                                    parse={dateParse}
                                    fullWidth
                                    disabled={isReadOnly}
                                />
                            }
                        </Box>
                        <Box flex={3} mr={{ xs: 0, sm: '0.5em' }}>
                            {(props.record.id === undefined) ?
                                <SelectInput
                                    label="Conceptos a Incluir"
                                    source="concepts"
                                    choices={conceptsData}
                                    initialValue={2}
                                    validate={requiredValidate}
                                    fullWidth
                                    disabled={isReadOnly}
                                /> :
                                <SelectInput
                                    label="Conceptos a Incluir"
                                    source="concepts"
                                    choices={conceptsData}
                                    validate={requiredValidate}
                                    fullWidth
                                    disabled={isReadOnly}
                                />
                            }
                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label={false}
                                source="pointOfSale"
                                validate={requiredValidatePointOfSale}
                                fullWidth
                                disabled={isReadOnly}
                            />
                        </Box>
                        <Box flex={2} ml={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label={false}
                                source="id"
                                validate={requiredValidateLengthInvoice}
                                fullWidth
                                disabled={isReadOnly}
                            />
                        </Box>
                    </Box>
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        </Box>
                    </Box>

                    <FormDataConsumer>
                        {({ formData }) => (formData.concepts !== 1 && formData.concepts !== undefined) &&
                            <PurchaseInvoiceDates {...props} />
                        }
                    </FormDataConsumer>

                    <SectionTitle label="Datos del Proveedor" />
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
                            <ReferenceInput
                                label="Busque proveedor por Nombre o Fantasía"
                                source="supplierId" //TODO DEBE SER XQ ES STRING
                                reference="suppliers"
                                sort={{ field: 'name', order: 'ASC' }}
                                onChange={handleSuppliers}
                                fullWidth
                                resettable
                            >
                                <AutocompleteInput
                                    optionText={(record) => {
                                        return record ? `${record.name} - ${record.nameFantasy}` : '';
                                    }}
                                    disabled={isReadOnly}
                                    validate={requiredValidate}
                                />
                            </ReferenceInput>
                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <AutocompleteInput
                                label="Tipo de Comprobante"
                                source="invoiceType"
                                choices={purchaseInvoiceData}
                                validate={requiredValidate}
                                fullWidth
                                resettable
                            />
                        </Box>
                    </Box>

                    <FormDataConsumer>
                        {({ formData }) => (formData.supplierId !== undefined) &&
                            <SupplierCard id={formData.supplierId} />
                        }
                    </FormDataConsumer>
                </Box>
                <Box
                    flex={1}
                    ml={{ xs: 0, lg: '1em' }}
                    mt={{ xs: '1em', lg: 0 }}
                >
                    <SectionTitle label="Observaciones Adicionales" />
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="Observaciones"
                                source="observations"
                                parse={toUpperCase}
                                rows={13}
                                multiline
                                resettable
                                fullWidth
                                disabled={isReadOnly}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </CardContent>
    );
};

export default PurchaseInvoiceForm;
