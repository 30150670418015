import React from 'react';
import {
    TextInput,
    NumberInput,
} from 'react-admin';
import { Box, CardContent } from '@material-ui/core';
import SectionTitle from '../components/SectionTitle';

const ConsumableForm = () => {
    return (
        <CardContent>
            <Box display={{ md: 'block', lg: 'flex' }}>
                <Box
                    flex={2}
                    ml={{ xs: 0, lg: '1em' }}
                    mt={{ xs: '1em', lg: 0 }}
                >
                    <SectionTitle label="Datos del Consumible" />
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="Código"
                                source="code"
                                disabled={true}
                                fullWidth
                            />
                        </Box>
                        <Box flex={2} ml={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="Nombre"
                                source="name"
                                disabled={true}
                                fullWidth
                            />
                        </Box>
                    </Box>
                    <SectionTitle label="Control de Stock" />
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <NumberInput
                                label="Cantidad Pañol"
                                source="quantity"
                                disabled={true}
                                fullWidth
                            />
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <NumberInput
                                label="Cantidad Deposito"
                                source="warehouseQuantity"
                                disabled={true}
                                defaultValue={0}
                                fullWidth
                            />
                        </Box>                        
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <NumberInput
                                label="Cantidad Minima"
                                source="minQuantity"
                                disabled={true}                                
                                fullWidth
                            />
                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <NumberInput
                                label="Cantidad Maxima"
                                source="maxQuantity"
                                disabled={true}
                                fullWidth
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </CardContent>
    );
};

export default ConsumableForm;
