import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ItemField from '../components/ItemField';
import { currencyFormatter } from '../utils/functions';

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

const ListItemsTotalPrice = (props) => {
    console.log('props.record.items', props);
    const [open, setOpen] = useState(false);
    const classes = useRowStyles();

    function calculateTotalUsed(items) {
        return items.map(({ price, itemQuantity }) => price * itemQuantity).reduce((sum, i) => sum + i, 0);
    }
        
    function calculateQuantityUsed(items) {
        return items.map(({ itemQuantity }) => itemQuantity).reduce((sum, i) => sum + i, 0);
    }

    // function calculateTotalConfirmed(items) {
    //     return items.map(({ price, itemQuantityUsed }) => price * itemQuantityUsed).reduce((sum, i) => sum + i, 0);
    // }

    // function calculateQuantityConfirmed(items) {
    //     return items.map(({ itemQuantityUsed }) => itemQuantityUsed).reduce((sum, i) => sum + i, 0);
    // }


    // TODO: Tener en cuenta que el campo 'itemQuantityUsed' es el que se usa para restar stock en el pañol de artículos
    function calculateQuantity(items) {
        if (items.length === 0) return 0;
    
        return items.reduce((sum, item) => {
            const quantity = item.dateTimeConfirmed || item.userNameConfirmed
                ? item.itemQuantityUsed
                : item.itemQuantity;
            return sum + quantity;
        }, 0);
    }

    // TODO: Tener en cuenta que el campo 'itemQuantityUsed' es el que se usa para restar stock en el pañol de artículos
    function calculateTotal(items) {
        if (items.length === 0) return 0;
    
        return items.reduce((sum, { price, itemQuantity, itemQuantityUsed, dateTimeConfirmed, userNameConfirmed }) => {
            const quantity = dateTimeConfirmed || userNameConfirmed ? itemQuantityUsed : itemQuantity;
            return sum + price * quantity;
        }, 0);
    }

    const totalUsed = currencyFormatter(calculateTotalUsed(props.record.items));
    const quantityUsed = calculateQuantityUsed(props.record.items);

    // const totalConfirmed = currencyFormatter(calculateTotal(props.record.items));
    // const quantityConfirmed = calculateQuantityConfirmed(props.record.items);

    const total = currencyFormatter(calculateTotal(props.record.items));
    const quantity = calculateQuantity(props.record.items);

    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>{''}</TableCell>
                        <TableCell align="right">{'Total Cantidad (Usado)'}</TableCell>                    
                        <TableCell align="right">{'Total Monto (Usado)'}</TableCell>

                        <TableCell align="right">{'Total Cantidad'}</TableCell>
                        <TableCell align="right">{'Total Monto'}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <Fragment>
                        <TableRow className={classes.root}>
                            <TableCell>
                                <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </IconButton>
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {'Materiales y/o Repuestos'}
                            </TableCell>
                            <TableCell align="right">{quantityUsed}</TableCell>
                            <TableCell align="right">{totalUsed}</TableCell>

                            <TableCell align="right">{quantity}</TableCell>
                            <TableCell align="right">{total}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                <Collapse in={open} timeout="auto" unmountOnExit>
                                    <Box margin={1}>
                                        <Table size="small" aria-label="purchases">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>{'Nombre'}</TableCell>
                                                    <TableCell align="right">{'Cantidad (Usado)'}</TableCell>
                                                    <TableCell align="right">{'Cantidad (Confirmado)'}</TableCell>
                                                    <TableCell align="right">{'Precio Unitario'}</TableCell>
                                                    <TableCell align="right">{'Subtotal'}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {props.record.items.map((item) => (
                                                    <TableRow key={item.id}>
                                                        <TableCell component="th" scope="row">
                                                            <ItemField id={item.id} />
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {item.itemQuantity}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {item.itemQuantityUsed}
                                                        </TableCell>                                                        
                                                        <TableCell align="right">
                                                            {currencyFormatter(item.price)}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {(item.dateTimeConfirmed || item.userNameConfirmed) ?
                                                                currencyFormatter((item.itemQuantityUsed * item.price))
                                                                :
                                                                currencyFormatter((item.itemQuantity * item.price))
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Box>
                                </Collapse>
                            </TableCell>
                        </TableRow>
                    </Fragment>
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default ListItemsTotalPrice;