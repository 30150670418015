import * as React from 'react';
import { useState } from 'react';
import {
    Title,
    ReferenceInput,
    AutocompleteInput,
    FormDataConsumer,
    SelectInput,
} from 'react-admin';
import { withTypes } from 'react-final-form';
import { Button, Card, Box, CardContent, CardActions, CircularProgress, makeStyles } from '@material-ui/core';
import ItemsByBusLinesDatagrid from './ItemsByBusLinesDatagrid';
import { requiredValidate } from '../../validators/validators';
import LineSelectInput from '../../vehicles/LineSelectInput';
import InfoMessage from '../components/InfoMessage';
import InfoIcon from '@material-ui/icons/Info';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
    root: {
        margin: '5px',
    },
});
const { Form } = withTypes();

const InfoMessageWithBreaks = () => (
    <Box display="flex" flexDirection="column" bgcolor="#e8f4fd" p={2} borderRadius={4} mb={2}>
        <Box display="flex" alignItems="center" mb={1}>
            <InfoIcon style={{ color: '#1976d2', marginRight: '8px' }} />
            <Typography variant="body2" style={{ color: '#1976d2' }}>
                Información de cantidades:
            </Typography>
        </Box>
        <Typography variant="body2" style={{ color: '#1976d2', marginBottom: '4px' }}>
            <b>'Total Cantidad'</b>: Cantidad total usado en la OT que restaron Stock (Es decir es la cantidad usado + la cantidad de los consumibles que el usuario confirmó o finalizó).
        </Typography>
        <Typography variant="body2" style={{ color: '#1976d2', marginBottom: '4px' }}>
            <b>'Total Monto'</b>: Monto total usado en la OT que restaron Stock (Es decir es el monto total usado + el monto total de los consumibles que el usuario confirmó o finalizó).
        </Typography>
        <Typography variant="body2" style={{ color: '#1976d2', marginBottom: '4px' }}>
            <b>*Nota</b>: Tener en cuenta que los consumibles son los artículos que tienen activado el campo 'Consumible' en el Pañol de artículos y una vez que el usuario confirma o finaliza la OT, se restan del Stock.
        </Typography>
    </Box>
);

const ReportItemsByBusLines = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState(false);
    const [values, setValues] = useState();
    const year = (new Date()).getFullYear();
    const startingYear = 2024; // TODO: ACA VA EL AÑO DE INICIO DE GASTOS DE ARTICULOS POR LINEA DE COLECTIVO
    const yearsRange = year - startingYear + 1;

    const years = Array.from({ length: yearsRange }, (_, index) => year - index);

    const handleSubmit = (values) => {
        if (values && Object.keys(values).length > 0) {
            setLoading(true);
            setValues(values);
            setState(true);
            setLoading(false);
        } else {
            setLoading(false);
        }
    };

    const handleButtonClick = () => {
        if (state) {
            setState(false);
            setValues(undefined);
        } else {
            handleSubmit(values);
        }
    };

    return (
        <div>
            <Form
                onSubmit={handleSubmit}
                initialValues={{ year }}
                render={({ handleSubmit, form }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <Card className={classes.root}>
                            <Title title={'Reporte de Gastos de Articulos por Linea de Colectivo'} />
                            <CardContent>
                                <InfoMessage
                                    message="Ten en cuenta que los gastos de artículos por línea de colectivo se calculan en base a las órdenes de trabajo que se han realizado en el mes y año seleccionado. Además, se incluyen todas las órdenes en todos sus estados: pendiente, en proceso, finalizada o facturada."
                                />
                                <br />
                                <InfoMessageWithBreaks />                            
                                <Box display={{ md: 'block', lg: 'flex' }}>
                                    <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                        <Box display={{ xs: 'block', sm: 'flex' }}>
                                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                                <ReferenceInput
                                                    label="Busque a su Cliente"
                                                    source="customerId"
                                                    reference="customers"
                                                    sort={{ field: 'name', order: 'ASC' }}
                                                    perPage={10}
                                                    fullWidth
                                                    resettable
                                                    onChange={() => {
                                                        form.change('lineId', "");
                                                    }}
                                                >
                                                    <AutocompleteInput
                                                        optionText="name"
                                                        validate={requiredValidate}
                                                    />
                                                </ReferenceInput>
                                            </Box>
                                        </Box>
                                        <Box display={{ xs: 'block', sm: 'flex' }}>
                                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                                <FormDataConsumer>
                                                    {({ formData, ...rest }) => formData.customerId !== undefined ? (
                                                        <LineSelectInput
                                                            customerId={formData.customerId}
                                                            validate={requiredValidate}
                                                            {...rest}
                                                        />
                                                    ) : null}
                                                </FormDataConsumer>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box
                                        flex={2}
                                        ml={{ xs: 0, lg: '1em' }}
                                        mt={{ xs: '1em', lg: 0 }}
                                    >
                                        <Box display={{ xs: 'block', sm: 'flex' }}>
                                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                                <SelectInput
                                                    label="Año"
                                                    source="year"
                                                    translateChoice={false}
                                                    choices={
                                                        years.map((year) => ({
                                                            id: year,
                                                            name: year,
                                                        }))
                                                    }
                                                    validate={requiredValidate}
                                                    fullWidth
                                                />
                                            </Box>
                                        </Box>
                                        <Box display={{ xs: 'block', sm: 'flex' }}>
                                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                                <CardActions>
                                                    <Button
                                                        variant="contained"
                                                        type="submit"
                                                        color="primary"
                                                        disabled={loading}
                                                        onClick={handleButtonClick}
                                                    >
                                                        {loading && (<CircularProgress size={25} thickness={2} />)}
                                                        {state ? 'Volver a Buscar Gastos de Articulos por Linea de Colectivo' : 'Buscar Gastos de Articulos por Linea de Colectivo'}
                                                    </Button>
                                                </CardActions>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                {state && values && values.customerId && values.lineId &&
                                    <ItemsByBusLinesDatagrid values={values} />
                                }
                            </CardContent>
                        </Card>
                    </form>
                )}
            />
        </div>
    );
};

export default ReportItemsByBusLines;
