import React from 'react';
import {
    TextInput,
    ReferenceInput,
    AutocompleteInput,
    FormDataConsumer,
    DateTimeInput,
    SelectInput
} from 'react-admin';
import { Box, CardContent } from '@material-ui/core';
import { requiredValidate } from '../validators/validators';
import { dateParse, dateParseDateTime, toChoices } from '../utils/functions';
import SectionTitle from '../components/SectionTitle';
import { useForm } from 'react-final-form';
import PurchaseEditCard from './PurchaseEditCard';
import PurchaseListCard from './PurchaseListCard';
import primaryAccountsData from '../components/data/primaryAccountsData';
import { secondaryAccountsData } from '../components/data/secondaryAccountsData';

const PaymentForm = (props) => {
    const form = useForm();
    const pointOfSale = '00001';

    form.change("pointOfSale", pointOfSale);

    const handleSuppliers = () => {
        localStorage.removeItem('purchasesPayments');
        localStorage.removeItem('methods');
    }

    const isReadOnly = (props.record.id !== undefined) ? true : false;
    const choicesPrimary = primaryAccountsData.filter(item => item.type !== 'RECIBO');

    return (
        <CardContent>
            <Box display={{ md: 'block', lg: 'flex' }}>
                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                    <SectionTitle label="Información de la Orden de Pago" />
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            {(props.record.id === undefined) ?
                                <DateTimeInput
                                    autoFocus
                                    label="Fecha"
                                    source="date"
                                    defaultValue={dateParseDateTime(Date.now())}
                                    validate={requiredValidate}
                                    parse={dateParse}
                                    fullWidth
                                />
                                :
                                <DateTimeInput
                                    autoFocus
                                    label="Fecha"
                                    source="date"
                                    validate={requiredValidate}
                                    parse={dateParse}
                                    disabled={isReadOnly}
                                    fullWidth
                                />
                            }

                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="Punto de Venta"
                                source="pointOfSale"
                                disabled={true}
                                fullWidth
                            />
                        </Box>
                    </Box>

                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <SelectInput
                                label="Cuenta Principal"
                                source="primaryAccount"
                                choices={choicesPrimary}
                                validate={requiredValidate}
                                onChange={handleSuppliers}
                                disabled={isReadOnly}
                                resettable
                                fullWidth
                            />
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <FormDataConsumer>
                                {({ formData, ...rest }) => (formData.primaryAccount === 'PROVEEDORES' && formData.primaryAccount !== undefined) ?
                                    <ReferenceInput
                                        label="Busque a su Proveedor"
                                        source="supplierId"
                                        reference="suppliers"
                                        sort={{ field: 'name', order: 'ASC' }}
                                        perPage={10}
                                        onChange={handleSuppliers}
                                        fullWidth
                                        resettable
                                    >
                                        <AutocompleteInput
                                            optionText="name"
                                            validate={requiredValidate}
                                            disabled={isReadOnly}
                                        />
                                    </ReferenceInput>
                                    :
                                    (formData.primaryAccount !== undefined) &&
                                    <SelectInput
                                        label="Cuenta Secundaria"
                                        source="secondaryAccount"
                                        choices={
                                            formData.primaryAccount
                                                ? toChoices(secondaryAccountsData[formData.primaryAccount])
                                                : []
                                        }
                                        onChange={handleSuppliers}
                                        validate={requiredValidate}
                                        disabled={isReadOnly}
                                        fullWidth
                                        resettable
                                        {...rest}
                                    />

                                }
                            </FormDataConsumer>
                        </Box>
                    </Box>

                    <FormDataConsumer>
                        {({ formData }) => (props.record.status === undefined && formData.primaryAccount === 'PROVEEDORES' && formData.supplierId !== undefined) ?
                            <PurchaseEditCard supplierId={formData.supplierId} />
                            :
                            (props.record.status !== undefined && formData.primaryAccount === 'PROVEEDORES') &&
                            <PurchaseListCard {...props} />
                        }
                    </FormDataConsumer>
                </Box>
            </Box>
        </CardContent>
    );
};

export default PaymentForm;
