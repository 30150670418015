import React from 'react';
import { Datagrid, TextField, NumberField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import WarehouseLinkField from './WarehouseLinkField';
import { Box, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';


const useListStyles = makeStyles({
    quantity: { color: 'purple' },
    warehouseQuantity: { color: 'green' },
});

// Mensaje de información con saltos de línea
const InfoMessageWithBreaks = () => (
    <Box display="flex" flexDirection="column" bgcolor="#e8f4fd" p={2} borderRadius={4} mb={2}>
        <Box display="flex" alignItems="center" mb={1}>
            <InfoIcon style={{ color: '#1976d2', marginRight: '8px' }} />
            <Typography variant="body2" style={{ color: '#1976d2' }}>
                Información de cantidades:
            </Typography>
        </Box>
        {/* <Typography variant="body2" style={{ color: '#1976d2', marginBottom: '4px' }}>
            <b>'Cantidad Usada (Todos los estados)'</b>: Total usado en OT, sin importar el estado, exepto las canceladas.
        </Typography>
        <Typography variant="body2" style={{ color: '#1976d2', marginBottom: '4px' }}>
            <b>'Cantidad Usada (Finalizadas)'</b>: Usado en OT finalizadas, finalizadas para entregar o facturadas, exepto las canceladas.
        </Typography> */}
        <Typography variant="body2" style={{ color: '#1976d2', marginBottom: '4px' }}>
            <b>'Cantidad Usada (Pendientes/Progreso)'</b>: Usado en OT pendientes o en progreso, exepto las canceladas.
        </Typography>
        <Typography variant="body2" style={{ color: '#1976d2', marginBottom: '4px' }}>
            <b>'Cantidad Usada (Confirmadas)'</b>: Confirmado o finalizado en la OT en todos los estados, exepto las canceladas (Es decir en el flujo de consumibles es la cantidad que el usuario confirmó para restar Stock, y tener en cuenta que solo son para los artículos que tienen activado el campo 'Consumible' en el Pañol de artículos).
        </Typography>
    </Box>
);

const WarehouseListDesktop = (props) => {
    const classes = useListStyles();
    return (
        <div>
            <InfoMessageWithBreaks />
            <Datagrid {...props} rowClick="edit" optimized>
                <WarehouseLinkField sortable={false} />
                <TextField label="Categoría" source="categoryName" sortable={false} />
                {/* <NumberField
                    label="Cantidad Usada (Todos los estados)"
                    source="workOrderItemUsedAllStatus"
                    cellClassName={classes.quantity}
                    sortable={false}
                />
                <NumberField
                    label="Cantidad Usada (Finalizadas)"
                    source="workOrderItemUsedFinished"
                    cellClassName={classes.quantity}
                    sortable={false}
                /> */}
                <NumberField
                    label="Cantidad Usada (Pendientes/Progreso)"
                    source="workOrderItemUsedInProgress"
                    cellClassName={classes.quantity}
                    sortable={false}
                />
                <NumberField
                    label="Cantidad Usada (Confirmado)"
                    source="workOrderItemUsedConfirmed"
                    cellClassName={classes.quantity}
                    sortable={false}
                />
                <NumberField
                    label="Cantidad Pañol"
                    source="quantity"
                    cellClassName={classes.quantity}
                    sortable={false}
                />
                <NumberField
                    label="Cantidad Depósito"
                    source="warehouseQuantity"
                    cellClassName={classes.warehouseQuantity}
                    sortable={false}
                />
            </Datagrid>
        </div>
    );
};

export default WarehouseListDesktop;
