import {
  fetchUtils,
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY,
} from "react-admin";

export default (apiUrl, httpClient = fetchUtils.fetchJson) => {
  /**
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} { url, options } The HTTP request parameters
   */
  const convertDataRequestToHTTP = (type, resource, params) => {
    let url = "";
    let obj = params.data;

    //TRIM al objeto params.data
    if (obj !== undefined) {
      Object.keys(obj).map(
        (k) => (obj[k] = typeof obj[k] == "string" ? obj[k].trim() : obj[k])
      );
    }

    const appendFormData = (entity, fields, obj, params) => {
      const formData = new FormData();
      formData.append(entity, JSON.stringify(obj));
      // field array de campos ["imageProfile", "files"]
      //ej: item = "params.data.imageProfile" or "params.data.files"
      fields.forEach(item => {
        if (params.data[item]) {
          formData.append(item, params.data[item].rawFile, params.data[item].rawFile.path);
        }
      });
      return formData;
    }

    const options = {};

    switch (type) {
      case GET_LIST: {
        /* 
        MY_SQL el paginado se hace de la siguiente manera:
        
        _start = nro de pagina en la que se encuentra
        _end = cantidad de registros por pagina
        
        el start tiene q ser el nro de pagina - 1 = 0 --> pagina 1

        ejemplo: _start=0&_end=10 --> pagina 1 con 10 registros
        ejemplo: _start=10&_end=20 --> pagina 2 con 10 registros
        ejemplo: _start=20&_end=30 --> pagina 3 con 10 registros
        */

        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;

        const _ranges = `_start=${(page - 1) * perPage}&_end=${page * perPage}`; //MONGO
        const _sort = `_sort=${field}&_order=${order}`; //MONGO

        const ranges = `range=${(page - 1) * perPage}&range=${page * perPage}`; //MYSQL
        const sort = `sort=${field}&sort=${order}`; //MYSQL

        const filter = params.filter.q === undefined ? "" : `&q=${params.filter.q.toUpperCase()}`;

        url = `${apiUrl}/${resource}?${_ranges}&${_sort}&${ranges}&${sort}${filter}`;

        if (resource === "employees") {//MONGO
          const status = params.filter.status === undefined ? "" : `&status=${params.filter.status}`;
          url = `${apiUrl}/${resource}?${_ranges}&${_sort}${status}${filter}`;
        }

        if (resource === "purchases") {//MONGO
          const supplierName = params.filter.supplierName === undefined ? "" : `&supplierName=${params.filter.supplierName.toUpperCase()}`;
          url = `${apiUrl}/${resource}?${_ranges}&${_sort}${supplierName}${filter}`;
        }

        if (resource === "vehicles") {//MONGO
          
          const patent = params.filter.patent === undefined ? "" : `&patent=${params.filter.patent.toUpperCase()}`;
          const lineId = params.filter.lineId === undefined ? "" : `&lineId=${params.filter.lineId}`;
          const customerId = params.filter.customerId === undefined ? "" : `&customerId=${params.filter.customerId}`;
          url = `${apiUrl}/${resource}?${_ranges}&${_sort}${patent}${lineId}${customerId}${filter}`;

          const filterByPatent = params.filter.filterByPatent === undefined ? false : true;
          if (filterByPatent === true) { // TODO --UN ENGENDRO QUE HICE PARA FILTRAR POR PATENTE
            const patent = params.filter.q === undefined ? "" : `&patent=${params.filter.q.toUpperCase()}`;
            url = `${apiUrl}/${resource}?${_ranges}&${_sort}${patent}`;
          }

        } 

        if (resource === "items") {//MYSQL
          const quantity = params.filter.quantity === undefined ? "" : `&quantity=${params.filter.quantity}`;
          const category = params.filter.category === undefined ? "" : `&category=${params.filter.category}`;
          const isConsumable = params.filter.isConsumable === undefined ? "" : `&isConsumable=${params.filter.isConsumable}`;
          url = `${apiUrl}/${resource}?${`_start=${page - 1}&_end=${perPage}`}&${_sort}${category}${quantity}${isConsumable}${filter}`;
        }

        if (resource === "warehouse") {//MYSQL
          const quantity = params.filter.quantity === undefined ? "" : `&quantity=${params.filter.quantity}`;
          const category = params.filter.category === undefined ? "" : `&category=${params.filter.category}`;
          url = `${apiUrl}/${resource}?${`_start=${page - 1}&_end=${perPage}`}&${_sort}${category}${quantity}${filter}`;
        }

        //TODO --REVISAR
        if (resource === "consumables") { //MYSQL
          url = `${apiUrl}/${resource}?${`_start=${page - 1}&_end=${perPage}`}&${_sort}&isConsumable=true${filter}`; // TODO CATEGORIA CONSUMIBLES
        }

        if (resource === "reports-rrhh") {//MONGO
          const start = params.filter.start === undefined ? "" : `&start=${params.filter.start}`;
          const end = params.filter.end === undefined ? "" : `&end=${params.filter.end}`;
          const status = params.filter.status === undefined ? "" : `&status=${params.filter.status}`;
          const shifts = params.filter.shifts === undefined ? "" : `&shifts=${params.filter.shifts}`;
          const days = params.filter.days === undefined ? "" : `&days=${params.filter.days}`;
          url = `${apiUrl}/${resource}?${_ranges}&${_sort}${start}${end}${status}${shifts}${days}${filter}`;
        }

        if (resource === "checks") {//MONGO
          const dueDate = params.filter.dueDate === undefined ? "" : `&dueDate=${params.filter.dueDate}`;
          const myCheck = params.filter.myCheck === undefined ? "" : `&myCheck=${params.filter.myCheck}`;
          const checkVision = params.filter.checkVision === undefined ? "" : `&checkVision=${params.filter.checkVision}`;
          url = `${apiUrl}/${resource}?${_ranges}&${_sort}${dueDate}${myCheck}${checkVision}${filter}`;
        }

        if (resource === "cash") {//MONGO
          const date = params.filter.date === undefined ? "" : `&date=${params.filter.date}`;
          const operationType = params.filter.operationType === undefined ? "" : `&operationType=${params.filter.operationType}`;
          const methods = params.filter.methods === undefined ? "" : `&methods=${params.filter.methods}`;
          url = `${apiUrl}/${resource}?${_ranges}&${_sort}${date}${operationType}${methods}${filter}`;
        }

        if (resource === "accounts") {//MONGO
          const start = params.filter.start === undefined ? "" : `&start=${params.filter.start}`;
          const end = params.filter.end === undefined ? "" : `&end=${params.filter.end}`;
          const primaryAccount = params.filter.primaryAccount === undefined ? "" : `&primaryAccount=${params.filter.primaryAccount}`;
          const secondaryAccount = params.filter.secondaryAccount === undefined ? "" : `&secondaryAccount=${params.filter.secondaryAccount}`;
          const customerId = params.filter.customerId === undefined ? "" : `&customerId=${params.filter.customerId}`;
          const supplierId = params.filter.supplierId === undefined ? "" : `&supplierId=${params.filter.supplierId}`;
          url = `${apiUrl}/${resource}?${_ranges}&${_sort}${start}${end}${primaryAccount}${secondaryAccount}${customerId}${supplierId}${filter}`;
        }

        if (resource === "invoices" || resource === "purchases-invoices" || resource === "receipts" || resource === "payments") {//MONGO
          const start = params.filter.start === undefined ? "" : `&start=${params.filter.start}`;
          const end = params.filter.end === undefined ? "" : `&end=${params.filter.end}`;
          url = `${apiUrl}/${resource}?${_ranges}&${_sort}${start}${end}`;
        }

        break;
      }
      case GET_ONE:
        url = `${apiUrl}/${resource}/${params.id}`;

        if (resource === "warehouse" || resource === "consumables") {//TODO --REMACHE Q TUVE Q HACER POR EL MOMENTO
          url = `${apiUrl}/items/${params.id}`;
        }
        break;
      case GET_MANY: {
        let idStr = "";
        let queryString = "";
        const query = params.ids.map((id) => idStr + `ids=${id}`);
        query.forEach((element) => { queryString += `${element}&`; });
        url = `${apiUrl}/${resource}/many?${queryString}`;
        break;
      }
      case GET_MANY_REFERENCE: {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;

        const ranges = `range=${(page - 1) * perPage}&range=${page * perPage - 1}`;
        const sort = `sort=${field}&sort=${order}`;
        const filter = `${params.target}=${params.id}`;

        url = `${apiUrl}/${resource}/reference?${filter}&${ranges}&${sort}`;

        if (resource === "consumables") {
           const _ranges = `_start=${(page - 1) * perPage}&_end=${page * perPage}`; //MONGO
           const _sort = `_sort=${field}&_order=${order}`; //MONGO
  
           url = `${apiUrl}/${resource}/reference?${_ranges}&${_sort}&${ranges}&${sort}&${filter}`;
         }

        break;
      }
      case UPDATE:
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = "PUT";

        if (resource === "warehouse") {//TODO REMACHE Q TUVE Q HACER POR EL MOMENTO
          url = `${apiUrl}/items/${params.id}`;
        }

        if (resource === "employees") {

          options.body = appendFormData("employee", ["imageProfile", "files"], obj, params);

        } else if (resource === "orders") {

          if (localStorage.cart !== null && localStorage.cart !== undefined) {//TODO AGREGAAR ITEMS
            let items = JSON.parse(localStorage.cart);
            let itemList = [];
            for (let i = 0; i < items.length; i++) {
              items[i] = {
                id: items[i].id,
                itemQuantity: items[i].itemQuantity,
                price: items[i].price,
                employeeId: items[i].employeeId,
                categoryId: items[i].categoryId,
                itemQuantityUsed: (items[i].itemQuantityUsed !== undefined) ? items[i].itemQuantityUsed : 0,
                userName: items[i].userName,
                createdAt: items[i].createdAt,
                updatedAt: items[i].updatedAt,
              }
              itemList.push(items[i]);
            }
            obj["items"] = itemList;
          }
          // localStorage.removeItem('cart'); //LO LIMPIA EN: OrderLinkField.jsx

          options.body = appendFormData("workOrder", ["files"], obj, params);

        } else if (resource === "invoices") {

          if (localStorage.invoiceCart !== null && localStorage.invoiceCart !== undefined) {//TODO AGREGAAR ITEMS
            obj["operationInfo"] = JSON.parse(localStorage.invoiceCart);
          }
          localStorage.removeItem('invoiceCart');

          options.body = JSON.stringify(obj);

        } else if (resource === "receipts") {

          if (localStorage.methods !== null && localStorage.methods !== undefined) {//TODO ESTA AL PEDOOOOOO FIJARSE
            obj["methods"] = JSON.parse(localStorage.methods);
          }
          localStorage.removeItem('methods');

          options.body = JSON.stringify(obj);

        } else if (resource === "purchases-invoices") {

          if (localStorage.purchasesInvoiceCart !== null && localStorage.purchasesInvoiceCart !== undefined) {//TODO AGREGAAR ITEMS
            obj["operationInfo"] = JSON.parse(localStorage.purchasesInvoiceCart);
          }
          localStorage.removeItem('purchasesInvoiceCart');

          options.body = JSON.stringify(obj);

        } else if (resource === "purchases") {

          if (localStorage.purchaseCart !== null && localStorage.purchaseCart !== undefined) {//TODO AGREGAAR ITEMS
            let items = JSON.parse(localStorage.purchaseCart);
            let itemList = [];
            for (let i = 0; i < items.length; i++) {
              items[i] = {
                id: items[i].id,
                itemQuantity: items[i].itemQuantity,
                price: items[i].price,
              }
              itemList.push(items[i]);
            }
            obj["items"] = itemList;
          }
          localStorage.removeItem('purchaseCart');

          options.body = appendFormData("purchase", ["files"], obj, params);

        } else if (resource === "categories") {

          options.body = appendFormData("category", ["image"], obj, params);

        } else if (resource === "items" || resource === "warehouse") {

          options.body = appendFormData("item", ["image"], obj, params);

        } else if (resource === "inspections") {
          if (localStorage.savedDrawing !== null && localStorage.savedDrawing !== undefined) {
            let draw = JSON.parse(localStorage.savedDrawing);
            obj["draw"] = draw;
          } else {
            obj["draw"] = null;
          }
          localStorage.removeItem('savedDrawing');
          options.body = JSON.stringify(obj);
        } else {
          options.body = JSON.stringify(obj);
        }

        break;
      case CREATE:
        url = `${apiUrl}/${resource}`;
        options.method = "POST";

        if (resource === "employees") {

          options.body = appendFormData("employee", ["imageProfile", "files"], obj, params);

        } else if (resource === "/workshop-api/api/orders") { //XQ SE CREA DESDE LA INSPECCION, ENTONCES LE PASA EL RECURSO DE ESTA MANERA

          //TODO AGREGAAR ITEMS
          if (localStorage.cart !== null && localStorage.cart !== undefined) {
            let items = JSON.parse(localStorage.cart);
            let itemList = [];
            for (let i = 0; i < items.length; i++) {
              items[i] = {
                id: items[i].id,
                itemQuantity: items[i].itemQuantity,
                price: items[i].price,
                employeeId: items[i].employeeId,
                categoryId: items[i].categoryId,
                itemQuantityUsed: (items[i].itemQuantityUsed !== undefined) ? items[i].itemQuantityUsed : 0,
                userName: items[i].userName,
                createdAt: items[i].createdAt,
                updatedAt: items[i].updatedAt,
              }
              itemList.push(items[i]);
            }
            obj["items"] = itemList;
          }
          localStorage.removeItem('cart');

          options.body = appendFormData("workOrder", ["files"], obj, params);

        } else if (resource === "invoices") {

          if (localStorage.invoiceCart !== null && localStorage.invoiceCart !== undefined) {//TODO AGREGAAR ITEMS
            obj["operationInfo"] = JSON.parse(localStorage.invoiceCart);
          }
          localStorage.removeItem('invoiceCart');

          options.body = JSON.stringify(obj);

        } else if (resource === "receipts") {

          if (localStorage.methods !== null && localStorage.methods !== undefined) {//TODO AGREGAAR ITEMS
            obj["methods"] = JSON.parse(localStorage.methods);
          }
          if (localStorage.invoicesReceipts !== null && localStorage.invoicesReceipts !== undefined) {//TODO AGREGAAR ITEMS
            let items = JSON.parse(localStorage.invoicesReceipts);
            let itemList = [];
            for (let i = 0; i < items.length; i++) {
              items[i] = {
                id: items[i].id,
                total: items[i].partialTotal - items[i].totalReceipt, //TODO --POR AHORA ES LO QUE FALTA CANCELAR PERO FIJARSE Q REPRESENTA ESTE CAMPO
                totalReceipt: items[i].totalReceipt
              }
              itemList.push(items[i]);
            }
            obj["invoices"] = itemList;
          }
          localStorage.removeItem('invoicesReceipts');
          localStorage.removeItem('methods');

          options.body = JSON.stringify(obj);

        } else if (resource === "payments") {

          if (localStorage.methods !== null && localStorage.methods !== undefined) {//TODO AGREGAAR ITEMS
            obj["methods"] = JSON.parse(localStorage.methods);
          }
          if (localStorage.purchasesPayments !== null && localStorage.purchasesPayments !== undefined) {//TODO AGREGAAR ITEMS
            let items = JSON.parse(localStorage.purchasesPayments);
            let itemList = [];
            for (let i = 0; i < items.length; i++) {
              items[i] = {
                id: items[i].id,
                total: items[i].total,
                totalPurchase: items[i].totalPurchase
              }
              itemList.push(items[i]);
            }
            obj["purchases"] = itemList;
          }
          localStorage.removeItem('purchasesPayments');
          localStorage.removeItem('methods');

          options.body = JSON.stringify(obj);

        } else if (resource === "purchases-invoices") {

          if (localStorage.purchasesInvoiceCart !== null && localStorage.purchasesInvoiceCart !== undefined) {//TODO AGREGAAR ITEMS
            obj["operationInfo"] = JSON.parse(localStorage.purchasesInvoiceCart);
          }
          localStorage.removeItem('purchasesInvoiceCart');

          options.body = JSON.stringify(obj);

        } else if (resource === "purchases") {

          if (localStorage.purchaseCart !== null && localStorage.purchaseCart !== undefined) {//TODO AGREGAAR ITEMS
            let items = JSON.parse(localStorage.purchaseCart);
            let itemList = [];
            for (let i = 0; i < items.length; i++) {
              items[i] = {
                id: items[i].id,
                itemQuantity: items[i].itemQuantity,
                price: items[i].price,
              }
              itemList.push(items[i]);
            }
            obj["items"] = itemList;
          }
          localStorage.removeItem('purchaseCart');

          options.body = appendFormData("purchase", ["files"], obj, params);

        } else if (resource === "categories") {

          options.body = appendFormData("category", ["image"], obj, params);

        } else if (resource === "items") {

          options.body = appendFormData("item", ["image"], obj, params);

        } else if (resource === "inspections") {
          if (localStorage.savedDrawing !== null && localStorage.savedDrawing !== undefined) {
            let draw = JSON.parse(localStorage.savedDrawing);
            obj["draw"] = draw;
          } else {
            obj["draw"] = null;
          }
          localStorage.removeItem('savedDrawing');
          options.body = JSON.stringify(obj);
        } else {
          options.body = JSON.stringify(obj);
        }

        break;
      case DELETE:
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = "DELETE";
        break;
      default:
        throw new Error(`Unsupported fetch action type ${type}`);
    }
    return { url, options };
  };

  /**
   * @param {Object} response HTTP response from fetch()
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} Data response
   */
  const convertHTTPResponse = (response, type, resource, params) => {
    const { json } = response;
    switch (type) {
      case GET_LIST:
      case GET_MANY_REFERENCE:
        return {
          data: json.data,
          total: json.total,
        };
      case CREATE:
        return {
          data: {
            ...params.data,
            id: json.id !== undefined ? json.id : json.data.id,
          },
        };
      default:
        return { data: json.data };
    }
  };

  /**
   * @param {string} type Request type, e.g GET_LIST
   * @param {string} resource Resource name, e.g. "posts"
   * @param {Object} payload Request parameters. Depends on the request type
   * @returns {Promise} the Promise for a data response
   */
  return (type, resource, params) => {
    if (type === UPDATE_MANY) {
      return Promise.all(
        params.ids.map((id) =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: "PUT",
            body: JSON.stringify(params.data),
          })
        )
      ).then((responses) => ({
        data: responses.map((response) => response.json),
      }));
    }
    if (type === DELETE_MANY) {
      return Promise.all(
        params.ids.map((id) =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: "DELETE",
          })
        )
      ).then((responses) => ({
        data: responses.map((response) => response.json),
      }));
    }

    const { url, options } = convertDataRequestToHTTP(type, resource, params);
    return httpClient(url, options).then((response) =>
      convertHTTPResponse(response, type, resource, params)
    )
  };
};
