import React, { useState, useEffect } from 'react';
import {
  useDataProvider,
  LinearProgress,
  useNotify,
} from 'react-admin';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Box, CardContent, useMediaQuery } from '@material-ui/core';
import CartItems from './CartItems';
import CartItemsMobileGrid from './CartItemsMobileGrid';
import SectionTitle from '../components/SectionTitle';
import authProvider from "../authProvider";
import { dateParseDateTime } from '../utils/functions';

const OrderFormItemsSearch = ({ formData, handleLoading, ...props }) => {
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const notify = useNotify();
  const value = '';
  const [products, setProducts] = useState([]);
  const [cart, setCart] = useState([]);
  const user = authProvider.getIdentity();

  const isXsmall = useMediaQuery(theme => theme.breakpoints.down('xs'));

  useEffect(() => {
    handleLoading(true); // Indicate loading started
    dataProvider
      .getList('items', {
        pagination: { page: 1, perPage: 999999 },
        sort: { field: 'name', order: 'ASC' },
        filter: { status: true },
      })
      .then(({ data }) => {
        let itemsFilter = {};
        if (props.id === undefined || formData.workOrderStatus === 'PENDIENTE') {
          //TODO - SOLO REPUESTOS, NEUMATICOS Y VIDRIOS
          itemsFilter = data.filter(item => item.categoryId !== '135')
        } else {
          //TODO - TODO MENOS HERRAMIENTAS
          itemsFilter = data.filter(item => item.categoryId !== '135')
        }
        setProducts(itemsFilter);
        if (props.id !== undefined) {
          let itemsOrder = formData.items;
          dataProvider.getMany('items', {
            ids: itemsOrder.map((item) => item.id),
          })
            .then(response => {
              let items = response.data;
              //Merge objects by id
              const result = itemsOrder.map(t1 => ({ ...t1, ...items.find(t2 => t2.id === t1.id) }));
              setCart(result);
              setLoading(false);
              handleLoading(false); // Actualiza el estado de loading
            });

        } else {
          if (JSON.parse(localStorage.getItem("cart"))) {
            setCart(JSON.parse(localStorage.getItem("cart")));
          }
          setLoading(false);
          handleLoading(false); // Actualiza el estado de loading
        }
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
        handleLoading(false); // Actualiza el estado de loading
      });
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const addToCart = (item) => {
    if (!item) return;

    // Confirmación antes de agregar el ítem
    const confirmAdd = window.confirm(`¿Deseas agregar el artículo "${item.name}" al carrito?`);
    if (!confirmAdd) {
      return; // Si el usuario cancela, no se agrega el ítem
    }

    const productList = [...cart];
    let found = false;

    if (productList.length > 0) {
      for (var i = 0; i < productList.length; i++) {
        if (productList[i].id === item.id) {
          productList[i].itemQuantity++;
          productList[i].userName = user.username;
          productList[i].updatedAt = dateParseDateTime(Date.now());
          found = true;
          break;
        }
      }
    }

    if (!found) {
      if (item.quantity === 0) {
        notify('resources.orders.notification.stock_error', 'warning', {}, false);
      } else {
        item.userName = user.username;
        item.createdAt = dateParseDateTime(Date.now());
        productList.push(item);
      }
    }
    setCart(productList);
    localStorage.setItem("cart", JSON.stringify(productList));
  };


  const addEmployeeToItem = (item, e) => {
    const productList = [...cart];
    const employeeId = e.target.value;
    if (item !== null) {
      if (productList.length > 0) {
        for (var i = 0; i < productList.length; i++) {
          if (productList[i].id === item.id) {
            productList[i].employeeId = employeeId;
            productList[i].userName = user.username;
            productList[i].updatedAt = dateParseDateTime(Date.now());
            break;
          }
        }
      }
      setCart(productList);
      localStorage.setItem("cart", JSON.stringify(productList));
    }
  }

  const deleteToCard = (item) => {
    const productList = [...cart];
    if (item !== null) {
      if (productList.length > 0) {
        for (var i = 0; i < productList.length; i++) {
          if (productList[i].id === item.id) {
            productList.splice(i, 1);
            break;
          }
        }
      }
      setCart(productList);
      localStorage.setItem("cart", JSON.stringify(productList));
    }
  }

  const changeQuantity = (item, e) => {
    const productList = [...cart];
    const index = productList.indexOf(item);
    if (e === '+') {
      productList[index].itemQuantity++;
    }
    else {
      if (productList[index].itemQuantity > 1) {
        productList[index].itemQuantity--;
      }
      else {
        productList.splice(index, 1);
      }
    }
    productList[index].userName = user.username;
    productList[index].updatedAt = dateParseDateTime(Date.now());
    setCart(productList);
    localStorage.setItem("cart", JSON.stringify(productList));
  }

  if (loading) return <LinearProgress />;
  if (error) { return <p>Error, por favor contactese con el administrador del sistema</p>; }
  if (!products || products.length === 0) return <p>No hay datos</p>;

  const choices = products.map((item) => ({
    id: item.id,
    code: item.code,
    name: item.name,
    itemQuantity: 1,
    price: item.price,
    quantity: item.quantity,
    minQuantity: item.minQuantity,
    maxQuantity: item.maxQuantity,
    categoryId: parseFloat(item.categoryId),
    employeeId: null,
    userName: item.userName || null,
    createdAt: item.createdAt || null,
    updatedAt: item.updatedAt || null,
  }));

  return (
    <CardContent>
      <Box display={{ md: 'block', lg: 'flex' }}>
        <Box flex={2} mr={{ md: 0, lg: '1em' }}>
          <SectionTitle label="Repuestos y/o Materiales" />
          <Box display={{ xs: 'block', sm: 'flex' }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <Autocomplete
                id="combo-box"
                options={choices}
                getOptionLabel={(option) => option.name && option.code ? `${option.name} (${option.code})` : option.name}
                value={value}
                onChange={(event, newValue) => {
                  addToCart(newValue)
                }}
                size="small"
                style={{ maxWidth: 600 }}
                renderInput={
                  (params) => <TextField {...params} label="Buscar Articulo" variant="filled" autoFocus />
                }
              />
              <br></br>

              {isXsmall ? (
                <CartItemsMobileGrid
                  id={props.id}
                  employees={(props.id !== undefined && formData.employeeId)}
                  products={cart}
                  changeQuantity={changeQuantity}
                  addEmployeeToItem={addEmployeeToItem}
                  deleteToCard={deleteToCard}
                />
              ) : (
                <CartItems
                  id={props.id}
                  employees={(props.id !== undefined && formData.employeeId)}
                  products={cart}
                  changeQuantity={changeQuantity}
                  addEmployeeToItem={addEmployeeToItem}
                  deleteToCard={deleteToCard}
                />)}
            </Box>
          </Box>
        </Box>
      </Box>
    </CardContent>
  );
}

export default OrderFormItemsSearch;
