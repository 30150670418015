import React from 'react';
import {
    TextInput,
    SelectInput,
    ReferenceInput,
    NumberInput,
    BooleanInput,
    AutocompleteInput
} from 'react-admin';
import { Box, CardContent } from '@material-ui/core';
import { requiredValidate, validateValue, requiredValidateValue, requiredValidateValueWithoutZero } from '../validators/validators';
import { toUpperCase } from '../utils/functions';
import SectionTitle from '../components/SectionTitle';
import NumberFormatCustom from '../components/NumberFormatCustom';
// import SupplierInput from '../components/input/SupplierInput';

const ItemForm = () => {
    return (
        <CardContent>
            <Box display={{ md: 'block', lg: 'flex' }}>
                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                    <SectionTitle label="Información del Articulo" />
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={2} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                autoFocus
                                label="Nombre"
                                source="name"
                                validate={requiredValidate}
                                parse={toUpperCase}
                                fullWidth
                            />
                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="Código"
                                source="code"
                                validate={requiredValidate}
                                parse={toUpperCase}
                                fullWidth
                            />
                        </Box>
                    </Box>
                    <SectionTitle label="Ubicacion en el Pañol" />
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <ReferenceInput
                                label="Categoria"
                                source="categoryId"
                                reference="categories"
                                sort={{ field: 'name', order: 'ASC' }}
                                validate={requiredValidate}
                                fullWidth
                            >
                                <SelectInput
                                    source="name"
                                />
                            </ReferenceInput>
                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="Ubicación"
                                source="location"
                                parse={toUpperCase}
                                multiline
                                resettable
                                fullWidth
                            />
                        </Box>
                    </Box>
                    <SectionTitle label="Control de Stock" />
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <NumberInput
                                label="Cant. Pañol"
                                source="quantity"
                                disabled={true}
                                min="0"
                                defaultValue={0}
                                validate={requiredValidateValue}
                                fullWidth
                            />
                        </Box>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <NumberInput
                                label="Cant. Deposito"
                                source="warehouseQuantity"
                                disabled={true}
                                min="0"
                                defaultValue={0}
                                validate={requiredValidateValue}
                                fullWidth
                            />
                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <NumberInput
                                label="Cant. Minima"
                                source="minQuantity"
                                min="0"
                                max="9999999"
                                validate={validateValue}
                                defaultValue={0}
                                fullWidth
                            />
                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <NumberInput
                                label="Cant. Maxima"
                                source="maxQuantity"
                                min="0"
                                max="9999999"
                                validate={validateValue}
                                defaultValue={0}
                                fullWidth
                            />
                        </Box>
                    </Box>
                    <SectionTitle label="Tipo Consumible" />
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <BooleanInput
                                label="¿El Articulo es del tipo consumible? (Es decir hasta que no se confirme su uso en el menú de Consumibles NO se descontará del stock)"
                                source="isConsumable"
                                fullWidth
                            />
                        </Box>
                    </Box>
                </Box>
                <Box
                    flex={2}
                    ml={{ xs: 0, lg: '1em' }}
                    mt={{ xs: '1em', lg: 0 }}
                >
                    <SectionTitle label="Precio Unitario" />
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="Precio"
                                source="price"
                                validate={requiredValidateValueWithoutZero}
                                InputProps={{
                                    inputComponent: NumberFormatCustom
                                }}
                                resettable
                                fullWidth
                            />
                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                        </Box>
                    </Box>
                    <SectionTitle label="Proveedor del Articulo" />
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                             {/* <SupplierInput /> //TODO FIJARSE MULTI PROVEEDORES 2024 */}
                            {/* //TODO No me funcionaba por el tiempo de datos del supplierId="10", en el supplier el id=10 tener en cuenta eso a la hora de usarlo */}
                            <ReferenceInput
                                label="Busque el Proveedor"
                                source="supplierId"
                                reference="suppliers"
                                perPage={10}
                                fullWidth
                                resettable
                            >
                                <AutocompleteInput
                                    optionText={(record) => {
                                        return record ? `${record.name} - ${record.nameFantasy}` : '';
                                    }} 
                                    validate={requiredValidate}
                                />
                            </ReferenceInput>
                        </Box>
                    </Box>
                    <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput
                                label="Descripción"
                                source="description"
                                parse={toUpperCase}
                                rows={3}
                                fullWidth
                                multiline
                                resettable
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </CardContent>
    );
};

export default ItemForm;
