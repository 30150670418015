import * as React from 'react';
import {
    EditButton,
    usePermissions
} from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
import EmployeeField from '../components/EmployeeField';
import ItemField from '../components/ItemField';
import { Chip } from '@material-ui/core';
import red from '@material-ui/core/colors/red';
import orange from '@material-ui/core/colors/orange';
import DateFieldFormatted from '../components/DateFieldFormatted';
import { getRolesWorkshopOnly } from '../appRolesComponents';

const useStyles = makeStyles(theme => ({
    root: { margin: '1em' },
    name: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
    },
    card: {
        height: '80%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0',
    },
    cardTitleContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardContent: {
        ...theme.typography.body1,
        display: 'flex',
        flexDirection: 'column',
    },
    margin: {
        margin: theme.spacing(1),
    },

}));

const ToolListMobile = ({ ids, data, basePath }) => {
    const classes = useStyles();

    const { permissions } = usePermissions();
    const isWorkshopOnly = getRolesWorkshopOnly(permissions);


    if (!ids || !data) {
        return null;
    }

    return (
        <div className={classes.root}>
            {ids.map(id => (
                <Card key={id} className={classes.card}>
                    <CardHeader
                        title={
                            <div className={classes.cardTitleContent}>
                                <div className={classes.name}>
                                    <h6>{`SEG-${data[id].id}`}</h6>
                                </div>
                                <EditButton
                                    resource="tools"
                                    basePath={basePath}
                                    record={data[id]}
                                />
                            </div>
                        }
                        subheader={
                            <div>
                                <div>
                                    {'Herramienta'}
                                    :&nbsp;
                                    <ItemField id={data[id].itemId} />
                                </div>
                                <div>
                                    {'Empleado'}
                                    :&nbsp;
                                    <EmployeeField id={data[id].employeeId} />
                                </div>
                            </div>
                        }
                    />

                    <CardContent className={classes.cardContent}>
                        <div>
                            {'Fecha Retiro'}
                            :&nbsp;
                            <DateFieldFormatted
                                record={data[id]}
                                source="start"
                                showTime={true}
                            />
                        </div>
                        <div>
                            {'Fecha Devolución'}
                            :&nbsp;
                            {(data[id].end) === null && data[id].status === true ?
                                <Chip
                                    label={'EN USO'}
                                    size="small"
                                    style={{ backgroundColor: red[200], border: 0 }}
                                />
                                :
                                <DateFieldFormatted
                                    record={data[id]}
                                    source="end"
                                    showTime={true}
                                />
                            }
                        </div>
                        <div>
                            {(data[id].end) !== null && data[id].status === false &&
                                <div>
                                    <Chip
                                        label={'DADA DE BAJA'}
                                        size="small"
                                        style={{ backgroundColor: orange[500], border: 0 }}
                                    />
                                </div>
                            }
                        </div>
                    </CardContent>

                    <CardContent className={classes.cardContent}>
                        {isWorkshopOnly &&
                            <div>
                                {'Usuario'}
                                :&nbsp;
                                {data[id].userName}
                            </div>
                        }
                    </CardContent>
                </Card>
            ))}
        </div>
    );
};

export default ToolListMobile;
