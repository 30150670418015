import React, { useState, useEffect } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead,
    TableRow, Paper, IconButton,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
    NumberField,
    Loading,
} from 'react-admin';
import ItemsByBusLinesExport from './ItemsByBusLinesExport';

const useStyles = makeStyles({
    root: {
        marginBottom: '1em',
        float: "right"
    },
    nestedRow: {
        backgroundColor: '#f0f0f0',
        paddingLeft: '2em',
    },
    expandIcon: {
        transition: 'transform 0.3s ease',
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    alignRight: {
        textAlign: 'right',
    },
});

const ItemsByBusLinesDatagrid = ({ values }) => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [expandedRows, setExpandedRows] = useState([]);
    const classes = useStyles();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const lineId = values.lineId === '' ? undefined : values.lineId;
            const year = values.year === '' ? undefined : values.year;
            const url = `https://api.marasa.com.ar/workshop-api/api/report-work-orders/bus-line/${lineId}/year/${year}`;

            try {
                const response = await fetch(url);
                const result = await response.json();
                setData(result.map((group, index) => ({
                    ...group,
                    id: `${group.busLineId}-${group.year}-${group.month}-${index}`
                })));
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [values.lineId, values.year]);

    const toggleRow = (rowId) => {
        setExpandedRows(prevState =>
            prevState.includes(rowId) ? prevState.filter(id => id !== rowId) : [...prevState, rowId]
        );
    };

    if (loading) { return <Loading /> }

    return (
        <div>
            <div className={classes.root}>
                <ItemsByBusLinesExport dataExport={data}/>
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Año</TableCell>
                            <TableCell>Mes</TableCell>
                            <TableCell>Cliente</TableCell>
                            <TableCell>Línea</TableCell>
                            <TableCell>Total Cantidad</TableCell>
                            <TableCell className={classes.alignRight}>
                                Total Monto
                            </TableCell>
                            <TableCell className={classes.alignRight}>
                                Total Monto a Facturar
                            </TableCell>
                            <TableCell>Acciones</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map(record => (
                            <React.Fragment key={record.id}>
                                <TableRow>
                                    <TableCell>{record.year}</TableCell>
                                    <TableCell>{record.month}</TableCell>
                                    <TableCell>{record.customerName}</TableCell>
                                    <TableCell>{record.busLineName}</TableCell>
                                    <TableCell>{record.totalQuantity}</TableCell>
                                    <TableCell className={classes.alignRight}>
                                        <NumberField
                                            record={record}
                                            source="total"
                                            locales="es-AR"
                                            options={{ style: 'currency', currency: 'ARS' }}
                                        />
                                    </TableCell>
                                    <TableCell className={classes.alignRight}>
                                        <NumberField
                                            record={record}
                                            source="totalToInvoiced"
                                            locales="es-AR"
                                            options={{ style: 'currency', currency: 'ARS' }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <IconButton
                                            onClick={() => toggleRow(record.id)}
                                            className={clsx(classes.expandIcon, {
                                                [classes.expandOpen]: expandedRows.includes(record.id),
                                            })}
                                        >
                                            <ExpandMoreIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                                {expandedRows.includes(record.id) && (
                                    <TableRow className={classes.nestedRow}>
                                        <TableCell colSpan={8}>
                                            <h3>Artículos utilizados en el mes {record.month}</h3>
                                            <Table size="small">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>#</TableCell>
                                                        <TableCell>Nombre</TableCell>
                                                        <TableCell>Categoría</TableCell>
                                                        <TableCell>Es Consumible</TableCell>
                                                        <TableCell>
                                                            Total Cantidad
                                                        </TableCell>
                                                        <TableCell className={classes.alignRight}>
                                                            Total Monto
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {record.items.map((item, number) => (
                                                        <TableRow key={item.id}>
                                                            <TableCell>{number + 1}</TableCell>
                                                            <TableCell>{item.name || 'Borrado en el sistema'}</TableCell>
                                                            <TableCell>{item.category || 'Borrado en el sistema'}</TableCell>
                                                            <TableCell>{item.isConsumable ? 'Sí' : 'No'}</TableCell>
                                                            <TableCell>{item.totalItemQuantity}</TableCell>
                                                            <TableCell className={classes.alignRight}>
                                                                <NumberField
                                                                    record={item}
                                                                    source="totalItemPrice"
                                                                    locales="es-AR"
                                                                    options={{ style: 'currency', currency: 'ARS' }}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default ItemsByBusLinesDatagrid;
