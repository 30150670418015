import * as React from "react";
import {
  List,
  Filter,
  AutocompleteInput,
  ReferenceInput,
} from "react-admin";
import ActionsTopToolbar from "../components/ActionsTopToolbar";
import ToolListMobile from "./ToolListMobile";
import ToolListDesktop from "./ToolListDesktop";
import { useMediaQuery } from "@material-ui/core";
import './style.css';

const ListFilters = (props) => (
  <Filter {...props}>
    <ReferenceInput
      label="Busque por empleado"
      source="employeeId"
      reference="employees"
      perPage={10}
      fullWidth
      resettable
      alwaysOn
      className="custom-filter-width"
    >
      <AutocompleteInput
        autoFocus
        optionText="fullName"
        emptyText="TODOS"
        choices={[{ id: '', fullName: 'TODOS' }]}
      />
    </ReferenceInput>
    <ReferenceInput
      label="Busque por herramienta"
      source="itemId"
      reference="items"
      perPage={10}
      filter={{ category: 135 }} //TODO SOLO HERAMIENTAS
      fullWidth
      resettable
      alwaysOn
      className="custom-filter-width"
    >
      <AutocompleteInput
        optionText={(record) =>
          record && record.name && record.code
            ? `${record.name} (${record.code})`
            : (record && record.name ? record.name : 'TODOS')
        }
        emptyText="TODOS"
        choices={[{ id: '', name: 'TODOS', code: '' }]}
      />
    </ReferenceInput>
  </Filter>
);


const ToolList = (props) => {
  const isXsmall = useMediaQuery(theme => theme.breakpoints.down('xs'));

  return (
    <List
      {...props}
      actions={<ActionsTopToolbar />}
      filters={<ListFilters />}
      sort={{ field: 'id', order: 'DESC' }}
      perPage={25}
      bulkActionButtons={false}
    >
      {
        isXsmall ? (
          <ToolListMobile />
        ) : (
          <ToolListDesktop />
        )
      }
    </List>
  );
};

export default ToolList;
