import React from 'react';
import {
    LinearProgress,
    useGetMany,
    ChipField
} from 'react-admin';

const EmployeesChipField = ({ employeeId }) => {
    const { data, loading, error } = useGetMany('employees', employeeId);

    if (loading) { return <LinearProgress />; }
    if (error) { return <p>ERROR</p>; }
    if (!data) { return null; }
    return (
        <div>
            {data.map(employee => (
                <ChipField size="small" record={employee} source="fullName" />
            ))}
        </div>
    );
}

export default EmployeesChipField;